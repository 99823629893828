import {
  GET_TRANSFERS,
  TRANSFERS_RECEIVED,
  CREATE_TRANSFER_RECEIVED,
  UPDATE_TRANSFER_RECEIVED,
  DELETE_TRANSFER_RECEIVED,
  SET_TRANSFERS_FILTER,
} from '../types/TransferTypes';
import cloneDeep from 'lodash.clonedeep';
import { getValueByKey } from '../lib/storage';
import { STORAGE_TRANSFERS_FILTERS } from '../types/Constants';

export interface TransferState {
  loading: boolean;
  list_data: any[];
  filter: any[];
  total_count: number;
}

const defaultState: TransferState = {
  loading: false,
  list_data: [],
  total_count: 0,
  filter: getValueByKey(STORAGE_TRANSFERS_FILTERS, [
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 10,
    },
    {
      attribute: 'sort',
      value: '-id',
    },
  ]),
};

export default (state: TransferState = defaultState, action: any) => {
  let tmpList: any = cloneDeep(state.list_data);
  switch (action.type) {
    case GET_TRANSFERS:
      return {...state, 
        loading: true};
    case TRANSFERS_RECEIVED:
      const total_count = action.count > -1 ? action.count : (state.total_count || 0);
      return {...state,
        loading: false,
        list_data: action.data,
        filter: action.filter,
        total_count: action.count,
      };
    case CREATE_TRANSFER_RECEIVED:
      return {...state, list_data: [action.item, ...state.list_data ], total_count: state.total_count + 1  };
    case UPDATE_TRANSFER_RECEIVED:
      return {...state, list_data: state.list_data.map((item: any) => item.id === action.item.id ? action.item : item)};
    case DELETE_TRANSFER_RECEIVED:
        tmpList = tmpList.filter((item: any) => item.id !== action.id);
        return {...state, list_data: tmpList, total_count: state.total_count - 1 };
    case SET_TRANSFERS_FILTER:
      return {...state, filter: action.filter};
    default:
       return state;
  }
};
