import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../reducers';
import moment from 'moment';
import { getBills, createBill, updateBill, deleteBill } from '../../BillActions';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';
import OrderItemsEditable from '../../../components/OrderItemsEditable/OrderItemsEditable';
import DateField from '../../../components/Fields/DateField';
import SelectField from '../../../components/Fields/SelectField';
import SwitchField from '../../../components/Fields/SwitchField';
import { I18n } from 'react-redux-i18n';
import TableColSettings from '../../../components/TableColSettings';
import { formatValue } from '../../../../lib/helper';

interface Field {
  name: string
  title: string
  type: string
  options: {
    editable?: boolean
    sorter?: boolean
    style: {[key:string]: any}
  }
  items?: {id: string, name: string, [key:string]: any}[]
  canBeHidden?: boolean
}

function Footer({ totals, currency }: any) {
  /*
    d_type: "currency"
    id: 8
    short_title: "Є"
    slug: "EUR"
    style: null
    title: "Євро"
  */
  const perCurrencyData = Array.isArray(totals.perCurrency) ?
    totals.perCurrency.map((item: any) => {
      const currencyTitle = currency.find((_currency: any) => _currency.id.toString() === item.currency_name)?.title;
      return {
        currencyTitle,
        total: item.total
      }
    }) : [];

  return (
    <div>
      {!!perCurrencyData.length && <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
          {perCurrencyData.map((item: any, index: number) => {
            return <div key={index}>{item.currencyTitle}: <strong>{(item.total || 0).formatNumber()}</strong></div>
          })}
      </div>}
      <p>{I18n.t('bill.Summary Total')}: <strong>{(totals.total || 0).formatNumber()}</strong></p>
    </div>
  );
}

function BillTable({
  counterparties,
  accounts,
  dictionaries,
  currencies,
  filter,
  data,
  total_count,
  default_types,
  totals,

  getBills,
  createBill,
  updateBill,
  deleteBill,
}: any) {
  const [cols, setCols] = useState(() => (localStorage.getItem('bill-table-col-setting') || '').split(','));
  const fields: Field[] = useMemo(() => [
    { name: 'counterparty_id', title: I18n.t('bill.Counterparty'), type: 'lookup', options: { style: { width: 250, minWidth: 250 } }, items: counterparties, canBeHidden: false },
    { name: 'account_id', title: I18n.t('bill.Account'), type: 'lookup', options: { style: { width: 150, minWidth: 150 } }, items: accounts, canBeHidden: default_types.account },
    { name: 'b_type', title: I18n.t('bill.Type'), type: 'lookup', options: { style: { width: 150, minWidth: 150 } }, items: dictionaries.map(({ id, short_title }: any) => ({ id, name: short_title })), canBeHidden: default_types.bill },
    { name: 'currency_name', title: I18n.t('bill.Currency name'), type: 'lookup', options: { style: { width: 80, minWidth: 80 } }, items: currencies.map(({ id, short_title }: any) => ({ id, name: short_title })), canBeHidden: default_types.currency },
    { name: 'currency_rate', title: I18n.t('bill.Currency rate'), type: 'string', options: { style: { width: 80, minWidth: 80 } } },
    { name: 'base_total', title: I18n.t('bill.Base total'), type: 'currency.4', options: { style: { width: 120, minWidth: 120 } }, canBeHidden: false },
    { name: 'total', title: I18n.t('bill.Total'), type: 'currency.2', options: { style: { width: 120, minWidth: 120, editable: false } } },
    { name: 'date', title: I18n.t('bill.Date'), type: 'date', options: { style: { width: 120, minWidth: 120 } } },
    { name: 'note', title: I18n.t('general.Field_Note'), type: 'string', options: { style: { width: 150, minWidth: 150 } } },
  ], [accounts, counterparties, currencies, default_types.account, default_types.bill, default_types.currency, dictionaries])

  const updateCols = useCallback((values: string) => {
    localStorage.setItem('bill-table-col-setting', values);
    setCols(values.split(','))
  }, [])
  useEffect(() => {
    if (!localStorage.getItem('bill-table-col-setting')) {
      localStorage.setItem('bill-table-col-setting',
        'counterparty_id,account_id,b_type,currency_name,currency_rate,base_total,total,date',
      );
    }
  }, []);

  const renderInput = useCallback((props: any) => {
    switch (props.inputType) {
      case 'date':
        return <div><DateField {...props}/></div>;
      case 'switch':
        return <SwitchField  {...props} inputProps={{disabled: true}}/>;
      case 'select':
        let options: any = [];
        if (props.name === 'counterparty_id') {
          options = counterparties.map((item: any) => ({title: item.name, value: item.id}));
        }
        if (props.name === 'account_id') {
          options = accounts.map((item: any) => ({title: item.name, value: item.id}));
        }
        if (props.name === 'b_type') {
          options = dictionaries
            .map((item: any) => ({title: item.short_title, value: item.id.toString()}));
        }
        if (props.name === 'currency_name') {
          options = currencies
            .map((item: any) => ({title: item.short_title, value: item.id.toString()}));
        }
        return <SelectField
          {...props}
          options={options}
          inputProps={{
            style: {
              minWidth: '100px',
              width: '100%',
            },
          }}
        />;
      default:
        return null;
    }
  }, [accounts, counterparties, currencies, dictionaries])

  const onTableCell = useCallback((col: any, record: any) => {
    switch (col.dataIndex) {
      case 'date':
        return {
          inputType: 'date',
          renderInput,
        };
      case 'counterparty_id':
      case 'account_id':
      case 'b_type':
      case 'currency_name':
        return {
          inputType: 'select',
          renderInput,
        };
      case 'currency_rate':
      case 'base_total':
        return {
          inputType: 'input',
        };
      case 'total':
        return {
          inputType: 'text',
        };
      case 'processed':
        return {
          inputType: 'switch',
          renderInput,
        };
      default:
        return {
          inputType: 'text',
        };
    }
  }, [renderInput]);

  const createItem = (data: any) => {
    createBill(data, filter);
  }
  const updateItem = (data: any) => {
    updateBill(data.id, data);
  }
  const deleteItem = (id: any) => {
    deleteBill(id);
  }
  const onChange = (pagination: any, filters: any, sorter: any) => {

    const _filter: any = cloneDeep(filter);

    const index = findIndex(filter, ['attribute', 'sort']);
    const indexLimit = findIndex(filter, ['attribute', 'limit']);
    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(_filter[indexLimit].value) - _filter[indexLimit].value;
      (indexSkip > -1) ? _filter[indexSkip].value = skip : _filter.push({attribute: 'skip', value: skip});
    }
    if (!Object.keys(sorter).length) {
      getBills(_filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      _filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      _filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    getBills(_filter);
  }


  const tableData: any [] = data.map((item: any) => {
    if (item.processed) {
      delete item.row_color;
      const bType = parseInt(item.b_type, 10)
      const foundDictionary = dictionaries.find(({ id }: { id: number }) => id === bType)
      if (foundDictionary?.style?.color) {
        item.row_color = foundDictionary?.style?.color;
      }

      const currencyName = parseInt(item.currency_name, 10)
      const foundCurrency = currencies.find(({ id }: { id: number }) => id === currencyName)
      if (!item.row_color && foundCurrency?.style?.color) {
        item.row_color = foundCurrency?.style?.color;
      }
    } else {
      item.row_color = undefined;;
    }
    return item;
  });

  let sortedInfo: any = {};

  let limit = 0;

  filter.forEach((item: any) => {
    if (item.attribute === 'sort') {
      sortedInfo = {
        order: item.value[0] === '-' ? 'descend' : 'ascend',
        columnKey: item.value[0] === '-' ? item.value.slice(1) : item.value,
      };
    }
    if (item.attribute === 'limit') {
      limit = item.value;
    }
  });

  const pagination = (total_count <= limit) 
    ? false
    : {
      total : total_count + Math.ceil(total_count / limit),
      pageSize: limit + 1,
    };

  const validateRules = [
    {name: 'counterparty_id', rules: ['required']},
    {name: 'base_total', rules: ['required', 'numeric']},
    {name: 'account_id', rules: ['required']},
    {name: 'b_type', rules: ['required']},
    {name: 'date', rules: ['required']},
    {name: 'currency_name', rules: ['required']},
    {name: 'currency_rate', rules: ['required', 'numeric']},
  ];

  const [hiddenCols, columns]: [any[], any[]] = useMemo(() => {
    const hiddenCols: any[] = [{ title: 'ID', dataIndex: 'id' }];
    const columns: any[] = [];
    fields.forEach(_field => {
      const {
        name,
        title,
        type,
        options: { editable = true, sorter = true, style = {} },
        items = [],
        canBeHidden = true
      } = _field

      const getValue = (value: any) => {
        if (type === 'lookup') {
          const foundOption = items.find((item: any) => item.id == value)
          return foundOption?.name || '';
        } else {
          return formatValue(value, type)
        }
      }
  
      if (cols.indexOf(name) > -1 || !canBeHidden) {
        columns.push({
          title,
          dataIndex: name,
          align: type.indexOf('currency') === 0 ? 'right' : 'left',
          editable,
          sorter,
          sortOrder: sortedInfo.columnKey === name && sortedInfo.order,
          // onCell: (record: any, _index: number) => ({ style: { background: record.row_color, ...style } }),
          onCell: (record: any, _index: number) => {
            const result = { ...onTableCell({ dataIndex: name }, record), style: { background: record.row_color, ...style } };
            return result;
          },
          render: (value: any, record: any) => <div>{getValue(value)}</div>,
        });
      } else {
        hiddenCols.push({
          title,
          dataIndex: name,
          render: (value: any) => getValue(value)
        });
      }      
    })
    return [hiddenCols, columns];
  }, [cols, fields, onTableCell, sortedInfo.columnKey, sortedInfo.order]);

  return (
    <div style={{ overflow: 'auto', width: '100%' }} className="small-table BillTable">
      <TableColSettings
        options = {[
          { label: I18n.t('bill.ID'), value: 'id', disabled: true },
          { label: I18n.t('bill.Counterparty'), value: 'counterparty_id', disabled: true },
          { label: I18n.t('bill.Account'), value: 'account_id', disabled: !default_types.account },
          { label: I18n.t('bill.Type'), value: 'b_type', disabled: !default_types.bill },
          { label: I18n.t('bill.Currency name'), value: 'currency_name', disabled: !default_types.currency },
          { label: I18n.t('bill.Currency rate'), value: 'currency_rate' },
          { label: I18n.t('bill.Base total'), value: 'base_total', disabled: true},
          { label: I18n.t('bill.Total'), value: 'total' },
          { label: I18n.t('bill.Date'), value: 'date' },
          { label: I18n.t('general.Field_Note'), value: 'note' },
        ]}
        data={cols.join(',')}
        onSave={(values: any) => {
          updateCols(values);
        }}
      />
      <OrderItemsEditable
        onChange={onChange}
        insertNewRow="first"
        btnCreateDisabled={false}
        items={tableData}
        create={createItem}
        update={updateItem}
        delete={deleteItem}
        changeStatus = {(id: number, processed: boolean) => updateBill(id, {processed})}

        validateRules={validateRules}
        columns={columns}
        onTableCell={onTableCell}

        initionalNewRowValues = { {
          currency_rate: '1.00',
          b_type: default_types.bill && default_types.bill.toString(),
          account_id: default_types.account,
          currency_name: default_types.currency && default_types.currency.toString(),
          date: moment().format('YYYY-MM-DD'),
        }}

        btnCreateShow={false}
        showNewLine={true}
        enableReinitialize={true}
        actionUpdateShow={(item: any) => !item.processed}
        actionDeleteShow={(item: any) => !item.processed}
        pagination = {pagination}
        footer={() => <Footer totals={totals} currency={currencies} />}
        operationsOnLeft={true}
        expandedRowRender={ (record: any) =>
          hiddenCols.map((item: any) => <div key={item.dataIndex}>
            {item.title} : {item.render ? item.render(record[item.dataIndex]) : record[item.dataIndex]}
          </div>)
        }
        propsTable={{
          tableLayout: 'fixed', //- | auto | fixed
          scroll: { x: true },
        }}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  data: state.bill.list_data,
  filter: state.bill.filter,
  totals: state.bill.totals,
  total_count: state.bill.total_count,
  counterparties: state.started_data.counterparties,
  dictionaries: state.started_data.dictionaries.filter((item: any) => item.d_type === 'bill'),
  currencies: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency'),
  accounts: state.started_data.accounts,
  default_types: state.default_values.values,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getBills,
      createBill,
      updateBill,
      deleteBill,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BillTable);
