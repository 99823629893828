import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row, Space } from 'antd';
import { AppState } from '../../reducers';
import OrderItemsEditable from '../components/OrderItemsEditable/OrderItemsEditable';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import Filter from '../components/Filter/Filter';
import { I18n } from 'react-redux-i18n';
import SelectField from '../components/Fields/SelectField';
import DateField from '../components/Fields/DateField';
import moment from 'moment';
import {
  createTransfer,
  updateTransfer,
  deleteTransfer,
  getTransfers,
} from './TransferActions';
import { getDefaultValues } from '../DefaultValue/DefaultValueActions';
import TableColSettings from '../components/TableColSettings';
import { formatValue } from '../../lib/helper';
import './style.scss';

class TransferList extends React.Component<any, any> {

  componentDidMount() {
    if (!localStorage.getItem('transfer-table-col-setting')) {
      localStorage.setItem('transfer-table-col-setting',
        'date,account_from_id,account_to_id,currency_name,currency_rate,total,note',
      );
    }

    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const transfer_limit: number = JSON.parse(retrievedObject).transfer_limit;
    const filter: any = cloneDeep(this.props.filter);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);

    if (indexLimit > -1) {
      filter[indexLimit].value = transfer_limit || 10;
    } else {
      filter.push({ attribute: 'limit', value: 10 });
    }

    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      filter[indexSkip].value = 0;
    }
    this.props.getTransfers(filter);
    this.props.getDefaultValues();
  }

  renderInput = (props: any) => {
    switch (props.inputType) {
      case 'date':
        return <div><DateField {...props}/></div>;
      case 'select':
        let options: any = [];
        if (props.name === 'account_from_id' || props.name === 'account_to_id') {
          options = this.props.accounts.map((item: any) => ({title: item.name, value: item.id}));
        }
        if (props.name === 'currency_name') {
          options = this.props.currency
            .map((item: any) => ({title: item.short_title, value: item.id.toString()}));
        }
        return <SelectField
          {...props}
          options={options}
          inputProps={{
            style: {
              minWidth: '100px',
              width: '100%',
            },
          }}
        />;
      default:
        return null;
    }
  }
  onTableCell = (col: any, record: any) => {
    switch (col.dataIndex) {
      case 'date':
        return {
          inputType: 'date',
          renderInput: this.renderInput,
        };
      case 'account_from_id':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      case 'account_to_id':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      case 'currency_name':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      case 'currency_rate':
        return {
          inputType: 'input',
          // style: {minWidth: '90px'},
        };
      case 'total':
          return {
            inputType: 'input',
            // style: {minWidth: '100px'},
          };
      default:
        return {
          inputType: 'text',
        };
    }
  }

  onChangeFilter = (filter: any) => {
    this.props.getTransfers(filter);
  }

  updateItem = (data: any) => {
    this.props.updateTransfer(data.id, data);
  }

  deleteItem = (data: any) => {
    this.props.deleteTransfer(data);
  }

  createItem = (data: any) => {
    this.props.createTransfer({...data}, this.props.filter);
  }

  onChange = (pagination: any, filters: any, sorter: any) => {
    const filter: any = cloneDeep(this.props.filter);

    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      if (indexSkip > -1) {
        filter[indexSkip].value = skip;
      } else {
        filter.push({attribute: 'skip', value: skip });
      }
    }

    if (!Object.keys(sorter).length) {
      this.props.getTransfers(filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getTransfers(filter);
  }

  public render() {
    const {data, accounts, filter, total_count, currency, default_types, is_mobile} = this.props;

    const tableData: any [] = data.map((item: any) => {
      let index = -1;
      delete item.row_color;
      if (!item.row_color && findIndex(currency, ['id', parseInt(item.currency_name, 10)]) > -1) {
        index = findIndex(currency, ['id', parseInt(item.currency_name, 10)]);
        if (currency[index] && currency[index].style && currency[index].style.color) {
          item.row_color = currency[index].style.color;
        }
      }
      return item;
    });

    let limit = 0;
    filter.forEach((item: any) => {
      if (item.attribute === 'limit') {
        limit = item.value;
      }
    });

    const pagination = (total_count <= limit)
      ? false
      : {
        total : total_count + Math.ceil(total_count / limit),
        pageSize: limit + 1,
      };

    const validateRules = [
      {name: 'date', rules: ['required']},
      {name: 'account_from_id', rules: ['required']},
      {name: 'account_to_id', rules: ['required']},
      {name: 'total', rules: ['required', 'numeric']},
      {name: 'currency_name', rules: ['required']},
      {name: 'currency_rate', rules: ['required', 'numeric']},
    ];

    const cols: any = (localStorage.getItem('transfer-table-col-setting') || '').split(',');
    const hiddenCols: any = [ {title: 'ID', dataIndex: 'id'}];

    const columns: any[] = [];

    columns.push({
      title: I18n.t('transfer.From account'),
      dataIndex: 'account_from_id',
      editable: true,
      render: (value: any, record: any) => {
        const index = findIndex(accounts, ['id', parseInt(value, 10)]);
        return {
          props: { style: { background: record.row_color, width: 150, minWidth: 150 } },
          children: (index > -1) && value && accounts[index].name,
        };
      },
    },
    {
      title: I18n.t('transfer.To account'),
      dataIndex: 'account_to_id',
      editable: true,
      render: (value: any, record: any) => {
        const index = findIndex(accounts, ['id', parseInt(value, 10)]);
        return {
          props: { style: { background: record.row_color, width: 150, minWidth: 150 } },
          children: (index > -1) && value && accounts[index].name,
        };
      },
    });

    if (cols.indexOf('currency_name') > -1 || !default_types.currency) {
      columns.push({
        title: I18n.t('transfer.Currency name'),
        dataIndex: 'currency_name',
        editable: true,
        render: (value: any, record: any) => {
          const index = findIndex(currency, ['id', parseInt(value, 10)]);
          return {
            props: { style: { background: record.row_color, width: 80, minWidth: 80 } },
            children: (index > -1) && value && currency[index].short_title,
          };
        },
      });
    } else {
      hiddenCols.push({title: I18n.t('transfer.Currency name'), dataIndex: 'currency_name',
        render: (value: any) => {
          const index = findIndex(currency, ['id', parseInt(value, 10)]);
          return (index > -1) && value && currency[index].short_title;
        }},
      );
    }

    if (cols.indexOf('currency_rate') > -1) {
      columns.push({
        title: I18n.t('transfer.Currency rate'),
        dataIndex: 'currency_rate',
        editable: true,
        render: (value: any, record: any) => ({
          props: { style: { background: record.row_color, width: 80, minWidth: 80 } },
          children: value,
        }),
      });
    } else {
      hiddenCols.push({title: I18n.t('transfer.Currency rate'), dataIndex: 'currency_rate'});
    }    
    columns.push({
      width : '13%',
      title: I18n.t('transfer.Total'),
      dataIndex: 'total',
      editable: true,
      align: 'right',
      render: (value: any, record: any) => ({
        props: { style: { background: record.row_color, width: 120, minWidth: 120 } },
        children: formatValue(value, 'currency.2'),
      }),
    });

    if (cols.indexOf('date') > -1) {
      columns.push({
        title: I18n.t('transfer.Date'),
        dataIndex: 'date',
        editable: true,
        render: (value: any, record: any) => ({
          props: { style: { background: record.row_color, paddingLeft: '0px !important', width: 120, minWidth: 120} },
          children: formatValue(value, 'date'),
        }),
      });
    } else {
      hiddenCols.push({title: I18n.t('transfer.Date'), dataIndex: 'date',
      render: (value: any) => (formatValue(value, 'date')) });
    }

    if (cols.indexOf('note') > -1) {
      columns.push({
        title: I18n.t('transfer.Note'),
        dataIndex: 'note',
        editable: true,
        render: (value: any, record: any) => ({
          props: { style: { background: record.row_color, width: 200, minWidth: 200 } },
          children: value,
        }),
      });
    } else {
      hiddenCols.push({title: I18n.t('transfer.Note'), dataIndex: 'note'});
    }

    return (
      <Space
        direction="vertical"
        size="middle"
        className = "small-table no-status"
        style={{ width: '100%', paddingBottom: is_mobile ? '100px' : '' }}
      >
        <Filter
          onChange={this.onChangeFilter}
          initialValues={filter}
          attributes={[
            {
              name: 'date',
              title: I18n.t('transfer.Date'),
              hyper_type: 'date',
              comparison: 'date_is_within',
            },
            {
              name: 'account_from_id',
              title: I18n.t('transfer.Account from'),
              hyper_type: 'select',
              options: accounts.map((item: any) => {
                return {
                  title: item.name,
                  value: item.id,
                };
              }),
            },
            {
              name: 'account_to_id',
              title: I18n.t('transfer.Account to'),
              hyper_type: 'select',
              options: accounts.map((item: any) => {
                return {
                  title: item.name,
                  value: item.id,
                };
              }),
            },
          ]}
        />
        <Row style = {{ overflow: 'auto', width: '100%' }} className="TransferTable">
          <TableColSettings
            options = {[
              { label: I18n.t('transfer.Date'), value: 'date' },
              { label: I18n.t('transfer.From account'), value: 'account_from_id', disabled: true},
              { label: I18n.t('transfer.To account'), value: 'account_to_id', disabled: true },
              { label: I18n.t('transfer.Currency name'), value: 'currency_name', disabled: !default_types.currency },
              { label: I18n.t('transfer.Currency rate'), value: 'currency_rate' },
              { label: I18n.t('transfer.Total'), value: 'total', disabled: true },
              { label: I18n.t('transfer.Note'), value: 'note' },
            ]}
            data = {localStorage.getItem('transfer-table-col-setting')}
            onSave = {(values: any) => {
              this.setState({values});
              localStorage.setItem('transfer-table-col-setting', values);
            }}
          />
          <OrderItemsEditable
            onChange={this.onChange}
            insertNewRow="first"
            btnCreateDisabled={false}
            items={tableData}
            create={this.createItem}
            update={this.updateItem}
            delete={this.deleteItem}
            changeStatus = {(id: number, processed: boolean) => this.props.updateTransfer(id, { processed })}
            validateRules={validateRules}
            columns={columns}
            onTableCell={this.onTableCell}
            initionalNewRowValues = {{
              currency_rate: '1.00',
              currency_name: default_types.currency && default_types.currency.toString(),
              date: moment().format('YYYY-MM-DD'),
            }}
            showNewLine={true}
            btnCreateShow={false}
            actionUpdateShow={(item: any) => !item.processed}
            actionDeleteShow={(item: any) => !item.processed}
            pagination={pagination}
            operationsOnLeft = {true}
            expandedRowRender = { (record: any) =>
              ((item: any) => <div key={item.dataIndex}>
                {item.title} : {item.render ? item.render(record[item.dataIndex]) : record[item.dataIndex]}
              </div>)
            }
            propsTable={{
              tableLayout: 'fixed', //- | auto | fixed
              scroll: { x: true },
            }}
          />
        </Row>
      </Space>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  total_count: state.transfer.total_count,
  filter: state.transfer.filter,
  data: state.transfer.list_data,
  accounts: state.started_data.accounts,
  currency: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency'),
  is_mobile: state.auth.is_mobile,
  default_types: state.default_values.values,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createTransfer,
      updateTransfer,
      deleteTransfer,
      getTransfers,
      getDefaultValues,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TransferList);
